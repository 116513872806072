<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-myclass-ref">
    <LxpMobileHeader>
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
      <template v-slot:right>
        <div v-if="notice.fileAtchYn === 'Y' && files && files.length > 0" class="util util-download">
          <a href="javascript:" class="util-actions util-actions-download" @click="showAttachments = !showAttachments">
            첨부파일 <i class="icon-download"></i>
          </a>
        </div>
      </template>
    </LxpMobileHeader>
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="board-view-container helpdesk">
        <div class="view-header">
          <div class="title-wrap">
            <h3 class="title">{{ notice.title }}</h3>
            <p class="subtitle"><span class="text">{{ timestampToDateFormat(notice.regDt, 'yyyy.MM.dd hh:mm') }} 등록</span></p>
          </div>
        </div>
        <div class="view-body min-component">
          <div class="view-content">
            <div class="kb-table-template">
              <div class="kb-table kb-table-bordered editor-area ck-editor__editable" v-html="notice.cn"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
  <div class="main-content main-component" v-if="showAttachments">
    <div class="kb-form-layer is-active">
      <div class="layer-container" @click="showAttachments = false">
        <div class="layer">
          <div class="kb-form-layer-options">
            <div class="layer-option">
              <ul class="layer-option-list">
                <li v-for="(file,idx) in files" :key="idx" class="layer-option-item">
                  <a class="layer-option-link" :title="file.fileNm" @click.stop="downloadAtch(file)">
                    <span class="layer-option-text">{{ file.fileNm != null ? file.fileNm : '-' }}</span>
                    <span class="text-muted text-nowrap">{{ file.fileSz > 0 ? convertToStorageBytes(file.fileSz) : 0 }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {convertToStorageBytes, getItem, getText, lengthCheck, timestampToDateFormat, winOpen} from '@/assets/js/util';
import {computed, ref} from 'vue';
import {ACT_GET_BOARD_LIST} from '@/store/modules/board/board';
import ApiService from '@/assets/js/api.service';
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import {goBack} from "@/assets/js/modules/common/common";

export default {
  name: 'MobileLearnBulletinView',
  components: {LxpMobileHeader},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const session = store.state.auth.session;
    const showAttachments = ref(false);

    const lrnPostSn = computed(() => route.params.lrnPostSn);

    const notice = ref({});
    const files = ref([]);

    const getNotice = () => {
      store.dispatch(`board/${ACT_GET_BOARD_LIST}`, {
        lrnPostSn: lrnPostSn.value
      }).then(res => {
        if (lengthCheck(res)) {
          notice.value = getItem(res);
          files.value = notice.value.boardFiles.filter(file => file.lrnPostFileAtchSn > 0);
          readBoard();
        }
      });
    };
    const readBoard = () => {
      ApiService.put(`/v1/app/learns/boards/${lrnPostSn.value}/inq`).then(() => {}).catch(() => {});
    }
    const downloadAtch = (atch) => {
      winOpen(`${process.env.VUE_APP_CLOUDFRONT_URL}/${session.itn ? atch.itnPath : atch.etnPath}`)
    }

    const clickBack = () => {
      goBack();
    }

    getNotice();

    return {
      route,
      router,
      store,
      showAttachments,
      notice,
      files,
      timestampToDateFormat,
      getText,
      downloadAtch,
      clickBack,
      convertToStorageBytes
    }
  }
};
</script>